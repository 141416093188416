export const languages = {
  skill: {
    en: 'Skills',
    es: 'Habilidades'
  },
  button: {
    en: 'Idioma: Español',
    es: 'Language: English'
  },
  profile: {
    en: 'Profile',
    es: 'Perfil'
  },
  p1: {
    en: 'I am interested in cloud infrastructure design, so in 2022 I started training. First with concepts related to networks, to later internalize myself in the services provided by AWS and finally I was certified as a Solutions Architect. In turn, to improve the workflow with these services, I learned about infrastructure as code and became a certified Terraform Associate.',
    es: 'Estoy interesado en el diseño de infrastructura en la nube, por lo que en el año 2022 empece a formarme. En primer lugar con conceptos referidos a redes, para luego interiorizarme en los servicios brindados por AWS y finalmente me certifique como Solutions Architect. A su vez, para mejorar el flujo de trabajo con estos servicios, aprendí acerca de infraestructura como código y me certifique como Terraform Associate.'
  },
  p2: {
    en: 'In a complementary way, I learned about frontend, starting with basic languages such as html, css and javascript to finally merge them with the React library, incorporating concepts such as state, routing and hooks, thus achieving the design of interactive user interfaces.',
    es: 'De manera complementaria, aprendí acerca de frontend, comenzando con lenguajes básicos como html, css y javascript para finalmente fusionarlos con la librería React, incorporando conceptos como estado, routing y hooks, logrando así el diseño de interfaces de usuario interactivas.'
  },
  p3: {
    en: 'On the other hand, I learned about DevOps: I used GitHub Actions to implement pipelines that automate processes, I used Docker Compose to work with images and containers, and AWS ECS as a container orchestrator in the cloud. I learned to work with GitHub and thus take advantage of Git as a version control system.',
    es: 'Por otro lado, aprendi acerca de DevOps: utilicé GitHub Actions para implementar pipelines que automatizan procesos, usé Docker Compose para trabajar con imagenes y contenedores, y AWS ECS como orquestador de contenedores en la nube. Aprendí a trabajar con GitHub y así aprovechar la ventaja de Git como sistema de control de versiones. También utilicé Docker para trabajar con contenedores.'
  },
  p4: {
    en: 'I am focused on continuing to improve my skills as a software engineer by working in a company that challenges me on a daily basis and allows me to continue growing as a professional.',
    es: 'Estoy enfocado en seguir mejorando mis habilidades como software engineer trabajando en una empresa que me presente desafíos a diario y me permita seguir creciendo como profesional.'
  },
  p5: {
    en: 'My mentor and reference, who has guided my learning so far is: ',
    es: 'Mi mentor y referencia, el cual ha guiado mi aprendizaje hasta el momento es: '
  },
  information: {
    en: 'Personal information',
    es: 'Información personal'
  },
  language: {
    en: 'Language',
    es: 'Idioma'
  },
  spanish: {
    en: 'Spanish: Native.',
    es: 'Español: Nativo.'
  },
  english: {
    en: 'English: Intermediate.',
    es: 'Inglés: Intermedio.'
  },
  certificates: {
    en: 'Certifications',
    es: 'Certificaciones'
  },
  formation: {
    en: 'Formation',
    es: 'Formación'
  },
  description1: {
    en: 'Solutions Architect certificate, issued by AWS.',
    es: 'Certificado como Solutions Architect, otorgado por AWS.'
  },
  description2: {
    en: 'Terraform Associate certificate, issued by Hashicorp.',
    es: 'Certificado como Terraform Associate, otorgado por Hashicorp.'
  },
  company3: {
    en: 'Sound Engineering',
    es: 'Ingeniero de Sonido'
  },
  description3: {
    en: 'Advanced student of Sound Engineering at the University of Tres de Febrero.',
    es: 'Estudiante avanzado en Ingenería de Sonido, en la Universidad de Tres de Febrero.'
  },
  aboutme: {
    en: 'A bit of me:',
    es: 'Un poco de mi:'
  },
  about1: {
    en: 'I like to spend time in the company of friends and family, eat, laugh and chat. I always find myself organizing or planning meetings, activities or future trips. I also enjoy cooking and animals.',
    es: 'Me gusta pasar el tiempo en compañía de amigos y familiares, comer, reir y charlar. Siempre me encuentro organizando o planeando reuniones, actividades o futuros viajes. También disfruto de cocinar y de los animales.'
  },
  about2: {
    en: 'I consider myself an adventurous person, who seeks challenges and constantly improve. In my spare time I try to do one of the activities mentioned below or, failing that, train to be able to perform at my best when the time comes. I also like to film me and my friends doing these things, to be able to remember the moments, see how I am evolving and the things that I have to improve.',
    es: 'Me considero una persona aventurera, que busca desafíos y mejorar constantemente. En mis tiempos libres intento hacer alguna de las actividades que menciono a continuación o, en su defecto, entrenar para poder rendir al máximo cuando llegue el momento. También me gusta filmar a mis amigos y a mi haciendo estas cosas, para poder recordar los momentos, ver cómo voy evolucionando y las cosas que tengo que mejorar.'
  },
  desc1: {
    en: 'I love Ski! It is one of my favorite activities. I skied for the first time when I was 15 years old and then every time we could go on vacation and ski. We usually go skiing as a family, with my mom, my brother and his girlfriend. I hope to continue doing it and get to know many ski resorts around the world.',
    es: 'Me encanta esquiar! Es una de mis actividades favoritas. Esquié por primera vez a mis 15 años y luego cada vez que pudimos salir de vacaciones y esquiar. Usualmente vamos en familia a esquiar, con mi mamá, mi hermano y su novia. Espero poder seguir haciendolo y conocer muchos centros de ski en todo el mundo.'
  },
  desc2: {
    en: 'Another thing I like to do is play the drums. At the age of 10 I began to venture into music, precisely as a drummer, taking private lessons at times and being part of various rock bands with friends. The genres in which I have participated are punk, rock, heavy metal and some percussion in folklore.',
    es: 'Otra cosa que me gusta hacer es tocar la bateria. A mis 10 años empecé a incursionar en la música, justamente como baterista, tomando clases particulares en algunos momentos y, formando parte de varias bandas de rock con amigos. Los géneros en los cuales he participado son punk, rock, heavy metal y algo de percusión en folklore.'
  },
  desc3: {
    en: 'It is an activity inherited from my dad, since he has been a lover of fly fishing for many years. I took my first fly fishing course in 2021 and from then on we went fishing for dorados in streams and rivers. It is a ground wire when you need to disconnect from life in the city, which allows you to enjoy the countryside, cool off in the water and be in silence, simply listening to the sounds of nature.',
    es: 'Se trata de una actividad heredada de mi papá, ya que él es un amante de la pesca con mosca desde hace muchos años. Hice mi primer curso de pesca con mosca en el año 2021 y a partir de entonces salimos a pescar dorados en arroyos y ríos. Es un cable a tierra cuando se necesita desconectar de la vida en la ciudad, que permite disfrutar del campo, refrescarse en el agua y estar en silencio, escuchando simplemente los sonidos de la naturaleza.'
  },
  desc4: {
    en: 'I also like to wakeboard. It is a fun sport that allows me to enjoy the hot weather and water in the summer seasons. The first time I did it was in 2021 and since then, when the temperature allows it, I go wakeboarding with my brother.',
    es: 'También me gusta hacer wakeboard. Es un deporte divertido que me permite disfrutar del clima caluroso y el agua en las temporadas de verano. La primera vez que hice fue en 2021 y desde entonces, cuando la temperatura lo permite, voy a hacer wakeboard con mi hermano.'
  },
  drums: {
    en: 'Drums',
    es: 'Bateria'
  },
  fishing: {
    en: 'Sport fishing',
    es: 'Pesca deportiva'
  },
  projects: {
    en: 'Personal projects',
    es: 'Proyectos personales'
  },
  date1: {
    en: 'jan. 2023 - feb. 2023',
    es: 'ene. 2023 - feb. 2023'
  },
  projectinfo1: {
    en: 'This is a project I did for a technical test related to DevOps.',
    es: 'Se trata de un proyecto que hice para una prueba técnica referida a DevOps.'
  },
  projectdescription1: {
    en: 'It consists of 3 points where I first made a network diagram of a web page with a frontend in JS and a backend with a relational and a non-relational database, using AWS services. Then I deployed a Django and React application with Dockerfiles, docker compose, nginx, gunicorn, uvicorn, AWS ECR, AWS ECS, both for a development and production environment, on a local pc and in the cloud. Finally, a CI/CD exercise where an image is automatically built in AWS ECR and updated on the platform chosen to host the page (AWS ECS), use GitHub Actions as a CI/CD tool.',
    es: 'Consiste en 3 puntos donde primero realicé un diagrama de red de una página web con frontend en JS y backend con una base de datos relacional y una no relacional, usando servicios de AWS. Luego realicé el deploy de una aplicación Django y React con Dockerfiles, docker compose, nginx, gunicorn, uvicorn, AWS ECR, AWS ECS, tanto para un entorno de desarrollo como de producción, en una pc local y en la nube. Por último, un ejercicio de CI/CD donde se buildea una imagen automáticamente en AWS ECR y se actualiza en la plataforma elegida para hostear la página (AWS ECS), use GitHub Actions cómo herramienta de CI/CD.'
  },
  date2: {
    en: 'jan. 2023',
    es: 'ene. 2023'
  },
  projectinfo2: {
    en: 'This is a Rick and Morty page where I made requests to the Rick and Morty API with axios and handled errors.',
    es: 'Se trata de una página de Rick and Morty donde hice requests a la API de Rick and Morty con axios y manejé errores.'
  },
  projectdescription2: {
    en: 'In this project I use AWS services to host the web page (S3, CloudFront, Route53, ACM) using Terraform as IaC, the frontend part was done with React (axios, useEffect, useState, JS, HTML, CSS, Bootstrap, Ant design) and the CI/CD part with GitHub Actions, implementing a pipeline to build the changes made to the page, upload them to the S3 bucket where the frontend files are located, and invalidate the CloudFront cache.',
    es: 'En este proyecto utilice los servicios de AWS para hostear la página web (S3, CloudFront, Route53, ACM) usando Terraform como IaC, la parte de frontend la hice con React (axios, useEffect, useState, JS, HTML, CSS, Bootstrap, Ant design) y la parte de CI/CD con GitHub Actions, implementando una pipeline para buildear los cambios realizados en la página, subirlos al bucket S3 donde se encuentran los archivos del frontend e invalidar el cache de CloudFront.'
  },
  date3: {
    en: 'nov. 2022 - dec. 2022',
    es: 'nov. 2022 - dic. 2022'
  },
  projectinfo3: {
    en: 'It is a Curriculum Vitae type page with a beginning referring to the professional part and an about me with personal content of free time.',
    es: 'Se trata de una página tipo Curriculum Vitae con un inicio referido a la parte profesional y un about me con contenido personal de tiempo libre.'
  },
  projectdescription3: {
    en: 'In this project I use AWS services to host the web page (S3, CloudFront, Route53, ACM) using Terraform as IaC, and the frontend part was done with React (useContext, useEffect, useState, JS, HTML, CSS, Bootstrap ).',
    es: 'En este proyecto utilice los servicios de AWS para hostear la página web (S3, CloudFront, Route53, ACM) usando Terraform como IaC, y la parte de frontend la hice con React (useContext, useEffect, useState, JS, HTML, CSS, Bootstrap).'
  },
}
